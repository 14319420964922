import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  Switch,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StyledField from '../../../components/Inputs/StyledField/StyledField';
import { usePagesDataCommonStyles } from '../../PagesDataCommon/PagesDataCommon.styles';
import MultipleAutocomplete from '../../../components/Inputs/MultipleAutocomplete';
import { fetchAnalyses } from '../../../services/analysesAPI';
import { getAltText, stopInputScroll } from '../../../constants';
import { useFileManager } from '../../../hooks/useFileManager';

interface IDataProps {
  darkTheme: boolean;
  setFieldsValues: (obj: any) => void;
  fieldsValues: {
    image: string | null;
    analyses: number[] | null;
    url: string;
    code: string;
    published: boolean;
    makeAtHome: boolean;
    sort: number;
  };
}

export const Data: React.FC<IDataProps> = ({
  darkTheme,
  setFieldsValues,
  fieldsValues,
}) => {
  const { openFileManager } = useFileManager(handleImageChange);
  const [analysesList, setAnalysesList] = React.useState([]);

  React.useEffect(() => {
    const getCategories = async () => {
      const list = await fetchAnalyses();
      setAnalysesList(list);
    };
    getCategories();
  }, []);

  function handleImageChange(file: string | null) {
    return function () {
      setFieldsValues((prevState: typeof fieldsValues) => {
        return {
          ...prevState,
          image: file,
        };
      });
    };
  }

  const handlePublishedChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldsValues((prevState: any) => {
        return {
          ...prevState,
          [key]: (e.target as HTMLInputElement).checked,
        };
      });
    };

  const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldsValues((prevState: any) => {
      return {
        ...prevState,
        [e.target.id]: (e.target as HTMLInputElement).value,
      };
    });
  };

  const handleAutocompleteChange =
    (key: string) => (e: any, values: { id: number; value: string }[]) => {
      const chosenIds = values.map(item => item.id);
      setFieldsValues((prevState: any) => {
        return {
          ...prevState,
          [key]: chosenIds,
        };
      });
    };

  const getAutocompleteValue = () => {
    const array = analysesList.filter((item: any) =>
      fieldsValues.analyses?.includes(item.id)
    );
    return array.map((obj: { id: number; value: string }) => obj.value);
  };

  const autocompleteValue = getAutocompleteValue();

  const { classes, cx } = usePagesDataCommonStyles();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginBottom: '16px',
        }}
      >
        <Box>
          <Typography component="h2" className={classes.descriptionText}>
            Зображення
          </Typography>
        </Box>
        <Box className={cx(classes.newsImgBlock)}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '200px',
              height: '150px',
              marginRight: '30px',
              border: '1px solid grey',
            }}
          >
            {fieldsValues.image ? (
              <>
                <img
                  src={fieldsValues.image}
                  alt={getAltText(fieldsValues.image)}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </>
            ) : (
              <IconButton onClick={() => openFileManager()}>
                <AddIcon
                  className={cx(
                    classes.addImageIcon,
                    darkTheme ? 'dark' : null
                  )}
                />
              </IconButton>
            )}
          </Box>
          {fieldsValues.image ? (
            <Box sx={{ display: 'flex', gap: 3 }}>
              <IconButton
                className={cx(classes.newsImgBlockButton)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="edit"
                onClick={() => openFileManager()}
              >
                <EditIcon
                  className={cx(classes.editIcon, darkTheme ? 'dark' : null)}
                />
              </IconButton>
              <IconButton
                className={cx(classes.newsImgBlockButton)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="delete"
                onClick={() => {
                  return setFieldsValues((prevState: any) => {
                    return {
                      ...prevState,
                      image: null,
                    };
                  });
                }}
              >
                <DeleteIcon
                  className={cx(classes.deleteIcon, darkTheme ? 'dark' : null)}
                />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
      <InputLabel
        htmlFor="analyses"
        className={cx(classes.label, darkTheme ? 'dark' : null)}
      >
        <div>
          Аналізи<span style={{ color: 'red', fontSize: '20px' }}>*</span>
        </div>
        <MultipleAutocomplete
          list={analysesList}
          darkTheme={darkTheme}
          id="analyses"
          className={cx(classes.autocomplete, darkTheme ? 'dark' : null)}
          onChange={handleAutocompleteChange('analyses')}
          value={autocompleteValue}
        />
      </InputLabel>
      <InputLabel
        htmlFor="url"
        className={cx(classes.label, darkTheme ? 'dark' : null)}
      >
        <div>
          URL<span style={{ color: 'red', fontSize: '20px' }}>*</span>
        </div>
        <StyledField
          id="url"
          variant="outlined"
          sx={{ width: '100%', mt: '16px' }}
          darkTheme={darkTheme}
          value={fieldsValues.url ? fieldsValues.url : ''}
          onChange={handleInputsChange}
        />
      </InputLabel>
      <InputLabel
        htmlFor="code"
        className={cx(
          classes.label,
          darkTheme ? 'dark' : null,
          'noBottomMargin'
        )}
      >
        Код
        <StyledField
          inputProps={{
            maxLength: 8,
          }}
          id="code"
          variant="outlined"
          sx={{ width: '100%', mt: '16px' }}
          darkTheme={darkTheme}
          value={fieldsValues.code}
          onChange={handleInputsChange}
        />
      </InputLabel>
      <FormControlLabel
        className={cx(classes.formControlLabel, darkTheme ? 'dark' : null)}
        label="Опубліковано"
        control={
          <Switch
            checked={fieldsValues.published}
            onChange={handlePublishedChange('published')}
            inputProps={{ 'aria-label': 'published' }}
            className={cx(classes.switch, darkTheme ? 'dark' : null)}
          />
        }
      />
      <InputLabel
        htmlFor="sort"
        className={cx(classes.label, darkTheme ? 'dark' : null)}
      >
        Сортування
        <StyledField
          type="number"
          inputProps={{
            min: '0',
          }}
          onWheel={e => {
            stopInputScroll(e);
          }}
          id="sort"
          variant="outlined"
          sx={{ width: '40%', mt: '16px' }}
          darkTheme={darkTheme}
          value={Number(fieldsValues.sort).toString()}
          onChange={handleInputsChange}
        />
      </InputLabel>
      <FormControlLabel
        className={cx(classes.formControlCheckBox, darkTheme ? 'dark' : null)}
        control={
          <Checkbox
            className={cx(classes.checkbox, darkTheme ? 'dark' : null)}
            checked={fieldsValues.makeAtHome}
            onChange={handlePublishedChange('makeAtHome')}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Можна здати вдома"
      />
    </>
  );
};
