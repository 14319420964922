import { styled } from '@mui/system';
import { TextField } from '@mui/material';
// import { text } from 'node:stream/consumers';
// import { useGlobalContext } from '../../../hooks/GlobalContext';
// import { TextFieldProps } from '@mui/material';
// import React from 'react';

// interface IStyledFieldProps {
//   darkTheme: boolean;
// }

interface IStyledFieldProps {
  darkTheme: boolean;
}

const StyledField = styled(TextField, {
  shouldForwardProp: prop =>
    prop !== 'color' &&
    prop !== 'variant' &&
    prop !== 'sx' &&
    prop !== 'darkTheme',
  name: 'SearchField',
  slot: 'Root',
})<IStyledFieldProps>(props => {
  return {
    input: {
      color: props.darkTheme ? '#ffffff !important' : '#000000 !important',
      fontSize: '14px !important',
      padding: '12px 14px !important',
    },
    textarea: {
      color: props.darkTheme ? '#ffffff !important' : '#000000 !important',
    },
    '&.dark label.Mui-focused': {
      color: '#ffffff !important',
    },
    '&.dark label': {
      color: '#ffffff !important',
    },
    '&.dark .MuiInput-underline:after': {
      borderBottomColor: '#ffffff !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey !important',
      },
      '&:hover fieldset': {
        borderColor: props.darkTheme
          ? '#ffffff !important'
          : '#000000 !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: props.darkTheme
          ? '#ffffff !important'
          : '#1976d2 !important',
      },
    },
  };
});

export default StyledField;
