import axios from 'axios';
import { toast } from 'react-toastify';
import { ILaboratoryHandbook } from '../types/laboratoryHandbookTypes';

export const fetchLaboratoryHandbookList = async () => {
  try {
    const response = await axios.get('/term');
    return response.data.data;
  } catch (error) {}
};

export const fetchLaboratoryHandbookById = async (id: string) => {
  try {
    const response = await axios.get(`/term/${id}`);
    return response.data.data;
  } catch (error) {}
};

export const handleSendLaboratoryHandbookData = async (
  id: string,
  data: ILaboratoryHandbook
) => {
  try {
    if (data.name.some(item => !item.value)) {
      toast.error(`Поле "Назва" обов'язкове`);
    } else if (data.description.some(item => !item.value)) {
      toast.error(`Поле "Опис" обов'язкове`);
    } else if (!data.url) {
      toast.error(`Поле "URL" обов'язкове`);
    } else if (!data.category) {
      toast.error(`Поле "Категорія" обов'язкове`);
    } else {
      const response = await axios.put(`/term/${id}`, data);
      toast.success('Дані збережено');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};

export const handleDeleteLaboratoryHandbook = async (id: string) => {
  try {
    const response = await axios.delete(`/term/${id}`);
    toast.success('Довідник видалено');
    return response.data.data;
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};
export const handleAddLaboratoryHandbook = async (
  data: ILaboratoryHandbook
): Promise<{ id: number } | undefined> => {
  try {
    if (data.name.some(item => !item.value)) {
      toast.error(`Поле "Назва" обов'язкове`);
    } else if (data.description.some(item => !item.value)) {
      toast.error(`Поле "Опис" обов'язкове`);
    } else if (!data.url) {
      toast.error(`Поле "URL" обов'язкове`);
    } else if (!data.category) {
      toast.error(`Поле "Категорія" обов'язкове`);
    } else {
      const response = await axios.post('/term/add', data);
      toast.success('Довідник додано');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};
