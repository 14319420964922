import axios from 'axios';
import { toast } from 'react-toastify';
import { ILaboratoryHandbookCategory } from '../types/laboratoryHandbookCategoryTypes';

export const fetchLaboratoryHandbookCategoryList = async () => {
  try {
    const response = await axios.get('/term-category');
    return response.data.data;
  } catch (error) {}
};

export const fetchLaboratoryHandbookCategoryById = async (id: string) => {
  try {
    const response = await axios.get(`/term-category/${id}`);
    return response.data.data;
  } catch (error) {}
};

export const handleSendLaboratoryHandbookCategoryData = async (
  id: string,
  data: ILaboratoryHandbookCategory
) => {
  try {
    if (data.name.some(item => !item.value)) {
      toast.error(`Поле "Назва" обов'язкове`);
    } else {
      const response = await axios.put(`/term-category/${id}`, data);
      toast.success('Дані збережено');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};

export const handleDeleteLaboratoryHandbookCategory = async (id: string) => {
  try {
    const response = await axios.delete(`/term-category/${id}`);
    toast.success('Категорію видалено');
    return response.data.data;
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};
export const handleAddLaboratoryHandbookCategory = async (
  data: ILaboratoryHandbookCategory
): Promise<{ id: number } | undefined> => {
  try {
    if (data.name.some(item => !item.value)) {
      toast.error(`Поле "Назва" обов'язкове`);
    } else {
      const response = await axios.post('/term-category/add', data);
      toast.success('Категорію додано');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};

export const fetchLaboratoryHandbookCategories = async () => {
  try {
    const response = await axios.get('/term-category/selections');
    return response.data.data;
  } catch (error) {}
};
