import React from 'react';
import { CKEditorEventAction, useCKEditor } from 'ckeditor4-react';
import { toast } from 'react-toastify';
import { getAltText } from '../../../constants';
import { useFileManager } from '../../../hooks/useFileManager';

interface IEditorProps {
  debug?: boolean;
  initData: string;
  onChange: (e: any) => void;
  name?: string;
}

const Editor: React.FC<IEditorProps> = props => {
  // console.log('props:', props.initData);
  // Use `useState` rather than `useRef` in order to trigger re-render.
  const [element, setElement] = React.useState<HTMLElement | null>(null);
  const { openFileManager } = useFileManager(handleImageChange);

  const { status } = useCKEditor({
    element,
    initContent: props.initData,
    dispatchEvent: ({ type, payload }) => {
      if (type === CKEditorEventAction.instanceReady) {
        payload.editor.on('beforeCommandExec', function (event: any) {
          if (event.data.name === 'paste') {
            event.editor._.forcePasteDialog = true;
          }
          if (
            event.data.name === 'pastetext' &&
            event.data.commandData.from === 'keystrokeHandler'
          ) {
            event.cancel();
          }
        });
      } else if (type === CKEditorEventAction.change) {
        props.onChange({ editor: payload.editor });
      } else if (type === CKEditorEventAction.beforeLoad) {
        if (!element) {
          const isPluginRegistered = payload.plugins.get('flmngr');
          if (!isPluginRegistered) {
            payload.plugins.add('flmngr', {
              init: function (editor: any) {
                editor.addCommand('openFlmgr', {
                  exec: function (ckeditor: any) {
                    openFileManager(undefined, undefined, undefined, ckeditor);
                  },
                });
                editor.ui.addButton('Timestamp', {
                  label: 'Відкрити файл менеджер',
                  command: 'openFlmgr',
                  toolbar: 'insert,0',
                  icon: 'https://img.icons8.com/?size=2x&id=RiINzCcRRibi&format=png',
                });
              },
            });
          }
        }
      }
    },
    config: {
      format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;div',
      stylesSet: [
        {
          name: 'Checkmark list',
          element: 'ul',
          attributes: { class: 'checkMark' },
        },
      ],
      extraPlugins: ['flmngr', 'colorbutton'],
    },
    subscribeTo: ['instanceReady', 'change', 'beforeLoad', 'destroy'],
  });

  function handleImageChange(file: string | string[] | null, editor?: any) {
    return function (key?: string) {
      if (editor) {
        if (editor.mode === 'wysiwyg') {
          // Insert HTML code.
          // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_editor.html#method-insertHtml
          editor.insertHtml(
            `<img alt="${getAltText(file as string)}" src="${file}"  />`
          );
        } else
          toast.warning(
            'Щоб вставити зображення треба бути в режимі редагування'
          );
      }
    };
  }

  return (
    <>
      <div
        ref={setElement}
        style={{
          visibility: status !== 'ready' ? 'hidden' : 'inherit',
        }}
      ></div>
    </>
  );
};

export default Editor;
