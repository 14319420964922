import React from 'react';
import { useGlobalContext } from '../../hooks/GlobalContext';

const LogoIcon: React.FC = () => {
  const { darkTheme } = useGlobalContext();

  return (
    <svg
      width="100"
      height="54"
      viewBox="0 0 153 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.2851 50.2522H53.8452V20.1979H61.3447V25.8662H61.4652C62.1404 23.9366 63.1291 22.4411 64.5036 21.3798C65.854 20.3185 67.3973 19.7878 69.0853 19.7878H70.7491V28.2059H68.8441C66.8427 28.2059 65.2511 28.8572 64.0695 30.1597C62.888 31.4622 62.2851 33.2471 62.2851 35.5145V50.2522Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M81.8417 20.1979H87.5808V27.8682H81.8417V38.9878C81.8417 40.0733 82.0829 40.8934 82.5892 41.4722C83.0715 42.0511 83.7467 42.3406 84.5666 42.3406H87.5808V50.2521H83.4332C79.9608 50.2521 77.5253 49.3114 76.0785 47.43C74.6316 45.5486 73.9082 42.823 73.9082 39.229V12.1416H81.8176V20.1979H81.8417Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M91.7764 20.198H99.6858V38.2643C99.6858 39.7839 100.072 40.9658 100.867 41.8101C101.663 42.6543 102.772 43.0643 104.219 43.0643C105.666 43.0643 106.775 42.6543 107.571 41.8101C108.367 40.9658 108.753 39.7839 108.753 38.2643V20.198H116.662V40.9658C116.662 44.1739 115.529 46.6342 113.286 48.3709C111.043 50.1076 108.005 50.9759 104.219 50.9759C100.433 50.9759 97.419 50.1076 95.1523 48.3709C92.8856 46.6342 91.7764 44.1498 91.7764 40.9658V20.198Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M121.099 50.2521V42.8229H131.299C131.999 42.8229 132.553 42.6782 132.939 42.3646C133.325 42.0511 133.518 41.6651 133.518 41.1827C133.518 40.6038 133.204 40.0249 132.601 39.4943C131.999 38.9395 131.251 38.4571 130.359 38.0471C129.467 37.6129 128.502 37.1063 127.441 36.5275C126.404 35.9244 125.416 35.3214 124.523 34.7184C123.631 34.1154 122.884 33.2953 122.281 32.3063C121.678 31.3174 121.364 30.232 121.364 29.0742C121.364 27.5063 121.63 26.1315 122.16 24.9737C122.691 23.8159 123.414 22.8752 124.33 22.1998C125.247 21.5244 126.284 21.0179 127.417 20.7043C128.55 20.3908 129.804 20.2219 131.155 20.2219H140.656V27.6028H131.516C130.889 27.6028 130.407 27.6993 130.07 27.8682C129.732 28.037 129.563 28.3988 129.563 28.9777C129.563 29.4119 129.877 29.846 130.504 30.2802C131.131 30.7385 131.926 31.1727 132.867 31.5586C133.807 31.9687 134.82 32.4752 135.929 33.0782C137.038 33.6812 138.051 34.3325 138.992 35.032C139.932 35.7074 140.704 36.5757 141.355 37.637C141.982 38.6983 142.295 39.8561 142.295 41.0862C142.295 43.9807 141.355 46.2239 139.45 47.84C137.545 49.432 135.109 50.2521 132.167 50.2521H121.099Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M49.384 26.7106C49.384 39.8081 38.7738 50.4211 25.6799 50.4211C12.6102 50.397 2 39.7839 2 26.7106C2 26.3246 2 25.9387 2.02411 25.5769C4.21849 28.3508 7.35331 30.7146 11.1151 32.4272C15.3109 34.3327 20.3266 35.4422 25.6799 35.4422C31.0332 35.4422 36.0248 34.3327 40.2448 32.4272C44.0307 30.7146 47.1655 28.3508 49.3358 25.5769C49.384 25.9387 49.384 26.3246 49.384 26.7106Z"
        fill="#F35C22"
      />
      <path
        d="M40.7272 29.8462C40.7754 29.3638 40.7995 28.9055 40.7995 28.399C40.7995 20.0533 34.0476 13.2995 25.7042 13.2995C17.3607 13.2995 10.6088 20.0533 10.6088 28.399C10.6088 28.8814 10.6329 29.3638 10.6811 29.8462C7.37753 28.7367 4.45974 27.2412 2.04834 25.5045C2.6753 12.9618 13.0202 3 25.7042 3C38.3881 3 48.733 12.9618 49.36 25.4804C46.9486 27.2412 44.0308 28.7126 40.7272 29.8462Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M142.44 12.1177H146.057V12.7931H144.634V16.58H143.863V12.7931H142.44V12.1177Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
      <path
        d="M146.563 12.1177H147.673L148.903 15.6152H148.927L150.132 12.1177H151.217V16.58H150.47V13.1549H150.446L149.216 16.6041H148.565L147.335 13.1549H147.311V16.6041H146.563V12.1177Z"
        fill={darkTheme ? '#ffffff' : '#70747C'}
      />
    </svg>
  );
};

export default LogoIcon;
