import axios from 'axios';
import { toast } from 'react-toastify';
import { IAdmin } from '../types/adminTypes';

export const fetchAdminsList = async () => {
  try {
    const response = await axios.get('/admin');
    return response.data.data;
  } catch (error) {}
};

export const fetchAdminById = async (id: string) => {
  try {
    const response = await axios.get(`/admin/${id}`);
    return response.data.data;
  } catch (error) {}
};

export const handleSendAdminData = async (id: string, data: IAdmin) => {
  try {
    if (!data.name) {
      toast.error(`Поле "Ім'я" обов'язкове`);
    } else if (!data.email) {
      toast.error(`Поле "E-mail" обов'язкове`);
    } else if (data.password !== '' && data.password.length < 6) {
      toast.error(`Пароль має бути мінімум 6 символів`);
    } else if (data.password2 !== data.password) {
      toast.error(`Паролі мають співпадати`);
    } else {
      const response = await axios.put(`/admin/${id}`, data);
      toast.success('Дані збережено');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};

export const handleDeleteAdmin = async (id: string) => {
  try {
    const response = await axios.delete(`/admin/${id}`);
    toast.success('Користувача видалено');
    return response.data.data;
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};

export const handleAddAdmin = async (
  data: IAdmin
): Promise<{ id: number } | undefined> => {
  try {
    if (!data.name) {
      toast.error(`Поле "Ім'я" обов'язкове`);
    } else if (!data.email) {
      toast.error(`Поле "E-mail" обов'язкове`);
    } else if (!data.password) {
      toast.error(`Поле "Пароль" обов'язкове`);
    } else if (data.password !== '' && data.password.length < 6) {
      toast.error(`Пароль має бути мінімум 6 символів`);
    } else if (data.password2 !== data.password) {
      toast.error(`Паролі мають співпадати`);
    } else {
      const response = await axios.post('/admin/add', data);
      toast.success('Користувача додано');
      return response.data.data;
    }
  } catch (error) {
    toast.error('Щось пішло не так. Спробуйте ще раз');
  }
};
