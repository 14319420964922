import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';
import CollapsedBreadcrumbs from '../components/Crumbs/Crumbs';
import { useNavBarStyles } from '../components/NavBar/NavBar.styles';
import TableComponent from '../components/TableComponent';
import { laboratoryHandbookCategoriesColumns } from '../TableColumns/TableColumns';
import { useGlobalContext } from '../hooks/GlobalContext';
import { Status } from '../constants';
import Loader from '../components/Loader';
import { fetchLaboratoryHandbookCategoryList } from '../services/laboratoryHandbookCategoriesAPI';

interface ILaboratoryHandbookPageCategoriesProps {
  pageName: string;
  link: string;
  parentPageName: string;
}

const LaboratoryHandbookCategoriesPage: React.FC<
  ILaboratoryHandbookPageCategoriesProps
> = ({ pageName, link, parentPageName }) => {
  const { idle, pending, resolved, rejected } = Status;
  const { classes, cx } = useNavBarStyles();
  const { darkTheme, rerenderComponent } = useGlobalContext();
  const [status, setStatus] = React.useState(idle);
  const [handbookCategoriesRows, setHandbookCategoriesRows] =
    React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setStatus(pending);
        const list = await fetchLaboratoryHandbookCategoryList();
        setHandbookCategoriesRows(list);
        setStatus(resolved);
      } catch (error) {
        setStatus(rejected);
      }
    };
    fetchData();
  }, [pending, rejected, resolved, rerenderComponent]);

  return (
    <>
      <Helmet>
        <title>Ortus - {pageName}</title>
      </Helmet>
      {status === pending && <Loader />}
      {status !== pending && status !== rejected && (
        <>
          <CollapsedBreadcrumbs
            darkTheme={darkTheme}
            linksData={{
              link,
              pageName,
              parentPageName,
            }}
          />
          <Typography
            component="h2"
            className={cx(classes.title, darkTheme ? 'dark' : null)}
          >
            {pageName}
          </Typography>
          <TableComponent
            darkTheme={darkTheme}
            columns={laboratoryHandbookCategoriesColumns}
            rows={handbookCategoriesRows}
            noCheckAll
            noCopy
          />
        </>
      )}
    </>
  );
};

export default LaboratoryHandbookCategoriesPage;
