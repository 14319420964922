import React from 'react';
import { InputLabel } from '@mui/material';
import { usePagesDataCommonStyles } from '../../../../PagesDataCommon/PagesDataCommon.styles';
import StyledField from '../../../../../components/Inputs/StyledField';

interface IBasicProps {
  darkTheme: boolean;
  setFieldsValues: (obj: any) => void;
  fieldsValues: { socials: { contact: string; value: string }[] };
}

export const Basic: React.FC<IBasicProps> = ({
  darkTheme,
  setFieldsValues,
  fieldsValues,
}) => {
  const { classes, cx } = usePagesDataCommonStyles();

  const handleFieldsChange = (index: number) => (e: any) => {
    setFieldsValues((prevState: any) => {
      const newArray = fieldsValues.socials.map((social, i) => {
        if (i === index) {
          return { ...social, value: (e.target as HTMLInputElement).value };
        } else {
          return social;
        }
      });
      return {
        ...prevState,
        socials: newArray,
      };
    });
  };

  return (
    <>
      {fieldsValues.socials.map((item, index) => {
        return (
          <InputLabel
            key={index}
            htmlFor={item.contact}
            className={cx(classes.label, darkTheme ? 'dark' : null)}
          >
            {item.contact.charAt(0).toUpperCase() + item.contact.slice(1)}
            <StyledField
              id={item.contact}
              variant="outlined"
              sx={{ width: '100%', mt: '16px' }}
              required
              darkTheme={darkTheme}
              value={item.value ? item.value : ''}
              onChange={handleFieldsChange(index)}
            />
          </InputLabel>
        );
      })}
    </>
  );
};
