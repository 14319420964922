import React from 'react';
import { InputLabel } from '@mui/material';
import StyledField from '../../../components/Inputs/StyledField/StyledField';
import { usePagesDataCommonStyles } from '../../PagesDataCommon/PagesDataCommon.styles';
import Autocomplete from '../../../components/Inputs/Autocomplete';

interface IDataProps {
  darkTheme: boolean;
  setFieldsValues: (obj: any) => void;
  fieldsValues: {
    url: string;
    category: number | null;
  };
  categories: { id: number; value: string }[];
}

export const Data: React.FC<IDataProps> = ({
  darkTheme,
  setFieldsValues,
  fieldsValues,
  categories,
}) => {
  const { classes, cx } = usePagesDataCommonStyles();

  const handleFieldsChange =
    (key?: string) => (e: any, newValue?: string | null) => {
      setFieldsValues((prevState: any) => {
        if (key) {
          const newCategoryTOSend = categories.filter(
            (item: any) => item.value === newValue
          )[0];
          const categoryId = newCategoryTOSend
            ? (newCategoryTOSend as { id: number; value: string }).id
            : null;
          return {
            ...prevState,
            [key]: categoryId,
          };
        } else {
          return {
            ...prevState,
            [e.target.id]: (e.target as HTMLInputElement).value,
          };
        }
      });
    };

  return (
    <>
      <InputLabel
        htmlFor="category"
        className={cx(classes.label, darkTheme ? 'dark' : null)}
      >
        <div>
          Категорія<span style={{ color: 'red', fontSize: '20px' }}>*</span>
        </div>
        <Autocomplete
          id="category"
          onChange={handleFieldsChange('category')}
          value={
            typeof fieldsValues.category === 'number'
              ? categories
                  .filter(
                    (category: any) => category.id === fieldsValues.category
                  )
                  .map((value: any) => value.value)
              : null
          }
          list={categories}
          className={cx(classes.autocomplete, darkTheme ? 'dark' : null)}
        />
      </InputLabel>
      <InputLabel
        htmlFor="url"
        className={cx(classes.label, darkTheme ? 'dark' : null)}
      >
        <div>
          URL<span style={{ color: 'red', fontSize: '20px' }}>*</span>
        </div>
        <StyledField
          id="url"
          variant="outlined"
          sx={{ width: '100%', mt: '16px' }}
          darkTheme={darkTheme}
          value={fieldsValues.url ? fieldsValues.url : ''}
          onChange={handleFieldsChange()}
        />
      </InputLabel>
    </>
  );
};
