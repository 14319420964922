import Flmngr from '@flmngr/flmngr-react';

const storage = process.env.REACT_APP_FILE_MANAGER_STORAGE_URL;
const server = process.env.REACT_APP_FILE_MANAGER_SERVER_URL;

export const useFileManager = (
  handleImageChange: (
    file: string | null,
    editor?: any
  ) => (key?: string, index?: number, mainKey?: string) => void
) => {
  const openFileManager = (
    key?: string,
    index?: number,
    mainKey?: string,
    editor?: any
  ) => {
    Flmngr.open({
      apiKey: 'FLMNFLMN', // default free key
      urlFileManager: server, // server
      urlFiles: storage, // file storage
      isMultiple: false, // let selecting a single file
      acceptExtensions: ['png', 'jpg', 'jpeg', 'gif', 'webp'],
      onFinish: (files: any) => {
        if (key && mainKey) {
          handleImageChange(files[0].url)(key, undefined, mainKey);
        } else if (key) {
          handleImageChange(files[0].url)(key, index);
        } else if (typeof index === 'number' && !key) {
          handleImageChange(files[0].url)(undefined, index);
        } else if (editor) {
          handleImageChange(files[0].url, editor)();
        } else {
          handleImageChange(files[0].url)();
        }
      },
    });
  };

  return {
    openFileManager,
  };
};
